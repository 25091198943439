<div style="background-image: url(assets/images/sea-beach.jpg); background-size: 100%; text-align: center;
  vertical-align: middle;
  line-height: 600px;
  background-position: center;
  " class="hero-background">
  <!-- <h1 class="strong" style="padding-top: 300px; color: white">About Us</h1> -->
  <h1 class="title hero-text">
    <span class="title-part line-1" style='padding-top: 35px;' >About Us</span>
  </h1>
</div>
<div class="fh5co-about ">
  <div class="col-md-6 col-md-offset-3  fh5co-heading">
    <!-- <h2>About Us</h2> -->
    <p>
      <strong style="color: black">Cross Abroad</strong> is an educational consultancy based in Beirut, Lebanon. Our aim
      is to consult educated students with
      services related to university programs, application process, accommodation and visa across the MENA region.
      We strive to ensure that our students receive superb career guidance based on their best interests and partner’s
      institution promotion. Our consulting services are offered by qualified degree holders who understand what it
      takes to apply for universities worldwide.
      In our field of business, we are consistently devoted to promoting educational quality and growing to become the
      world's most trusted educational consulting.
    </p>
  </div>


  <div class="container">
    <div class="row ">
      <div class="col-md-4" style="display: flex;
      justify-content: center;">
        <img src="assets/images/Mission.png" width="30%" style="margin-top: 20px;">
      </div>
      <div class="col-md-8">
        <h2>Our Mission</h2>
        <p>To become essential to our students by providing them with differentiated services in order to help them
          achieve their aspirations with the best possible institution advised that would enhance their career path
          through counseling & customized solutions offered for the ones aspiring to study overseas.
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" style="display: flex;
      justify-content: center;">
        <img src="assets/images/vision.png" width="30%" style="margin-top: 5px;">
      </div>
      <div class="col-md-8">
        <h2>Our Vision</h2>
        <p>
          To be always the desired organization that students would recommend in order to drive the development of a
          high performance learning culture.
      </div>
    </div>
  </div>
</div>

<hr style="display: block; height: 1px;
border: 0; border-top: 2px solid #33c9ff;
margin: 0px 300px 150px 300px; padding: 0; ">

<div id="fh5co-why-us" class="" style="padding: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
        <h2>Why Study Abroad?</h2>
        <p> We believe that studying abroad helps you to learn new languages, appreciate other cultures, overcome
          challenges of living
          in another country and gain a greater understanding of the world. These are all things that modern businesses
          look for when hiring, and such traits will only become more important in the future. </p>
      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/96.png" alt="96%" class="img-responsive"></span>
        <!-- <h3>Application</h3> -->
        <p>of students who study abroad say that it increased
          their self confidence.</p>

      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/80.png" alt="80%" class="img-responsive"></span>
        <!-- <h3>Visa</h3> -->
        <p>say that they acquired skills that sets for their
          career path.</p>

      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/64.png" alt="64%" class="img-responsive"></span>
        <!-- <h3>Accommodation</h3> -->
        <p>of employers thinks it's important for recruitment.
        </p>

      </div>
    </div>
  </div>
</div>

<hr style="display: block; height: 1px;
border: 0; border-top: 2px solid #33c9ff;
margin: 0px 300px 75px 300px; padding: 0; ">


<div id="fh5co-why-us" class="" style="padding: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
        <h2>Why Us?</h2>
        <p>Changing lives, one student at a time.</p>
      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/application.png" alt="Free HTML5 Templates"
            class="img-responsive"></span>
        <h3>Application</h3>
        <p>Our company assists you through the university program, application and acceptance process.  </p>

      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/accommodation.png" alt="Free HTML5 Templates"
            class="img-responsive"></span>
        <h3>Accommodation</h3>
        <p>We have special access to thousands of rooms through our accommodation partner in order to assist you with
          your journey.</p>

      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/visa.png" alt="Free HTML5 Templates" class="img-responsive"></span>
        <h3>Visa</h3>
        <p>Our counselling team assists you with your interview preparations and visa clearance.</p>
      </div>
    </div>
  </div>
</div>


<app-apply-now-card></app-apply-now-card>
