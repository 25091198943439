<aside id="fh5co-hero" class="js-fullheight">
  <div class="flexslider js-fullheight">
    <ul class="slides">
      <li class="slider-background"
        style="background-image: url(assets/images/eiffel_paris_sunset.jpg);">
        <div class="overlay-gradient"></div>
        <div class="container">
          <div class="col-md-10 col-md-offset-1 text-center js-fullheight slider-text">
            <div class="slider-text-inner">
              <h2 style="text-shadow: #020201 1px 1px, #040402 2px 2px;text-transform: uppercase; margin-top:-10px">Welcome to Cross
                Abroad!</h2>
              <h2 class='hero-quote'
                style="text-shadow: #020201 1px 1px, #040402 2px 2px; font-size: 30px; font-weight: 200!important;
                 /* font-family: 'system-ui'; */
                 margin-top: 0;
                 ">
                We are a team of consultants that deliver educational services.</h2>
              <p><a routerLink="apply-now" class="btn btn-primary btn-lg">Get Started</a></p>
            </div>
          </div>
        </div>
      </li>
      <li class="slider-background"
        style="background-image: url(assets/images/vancouver.jpg);">
        <div class="overlay-gradient"></div>
        <div class="container">
          <div class="col-md-10 col-md-offset-1 text-center js-fullheight slider-text">
            <div class="slider-text-inner">
              <h2 class='hero-quote'
                style="text-shadow: #020201 1px 1px, #040402 2px 2px; font-size: 50px; font-weight: 200!important;
                 /* font-family: 'system-ui'; */
                 ">
                Better learning future starts here</h2>
              <h2 style="text-shadow: #020201 1px 1px, #040402 2px 2px">Study in Canada</h2>
              <p><a routerLink="apply-now" class="btn btn-primary btn-lg">Apply Now</a></p>
            </div>
          </div>
        </div>
      </li>
      <li class="slider-background"
        style="background-image: url(assets/images/milan.jpg);">
        <div class="container">
          <div class="col-md-10 col-md-offset-1 text-center js-fullheight slider-text">
            <div class="slider-text-inner">
              <h2 class='hero-quote'
                style="text-shadow: #020201 1px 1px, #040402 2px 2px; font-size: 50px; font-weight: 200!important;
                 /* font-family: 'system-ui'; */
                 ">
                Rise to the challenge!</h2>
              <h2 style="text-shadow: #020201 1px 1px, #040402 2px 2px">Study in Italy</h2>
              <p><a routerLink="apply-now" class="btn btn-primary btn-lg">Apply Now</a></p>
            </div>
          </div>
        </div>
      </li>
      <li class="slider-background"
        style="background-image: url(assets/images/bigben.jpg);">
        <div class="container">
          <div class="col-md-10 col-md-offset-1 text-center js-fullheight slider-text">
            <div class="slider-text-inner">
              <h2 class='hero-quote'
                style="text-shadow: #020201 1px 1px, #040402 2px 2px; font-size: 50px; font-weight: 200!important;
                 /* font-family: 'system-ui'; */
                 ">
                Aim for excellence</h2>
              <h2 style="text-shadow: #020201 1px 1px, #040402 2px 2px">Study in UK</h2>
              <p><a routerLink="apply-now" class="btn btn-primary btn-lg">Apply Now</a></p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</aside>

<div id="fh5co-why-us" class="" style="padding-top: 20;">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
        <h2>Why Us?</h2>
        <p>Changing lives, one student at a time.</p>
      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/application.png" alt="Free HTML5 Templates"
            class="img-responsive"></span>
        <h3>Application</h3>
        <p>Our company assists you through the university program, application and acceptance process.</p>
      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/accommodation.png" alt="Free HTML5 Templates"
            class="img-responsive"></span>
        <h3>Accommodation</h3>
        <p>We have special access to thousands of rooms through our accommodation partner in order to assist you with
          your journey.</p>
      </div>
      <div class="col-md-4 text-center item-block">
        <span class="icon"><img src="assets/images/visa.png" alt="Free HTML5 Templates" class="img-responsive"></span>
        <h3>Visa</h3>
        <p>Our counselling team assists you with your interview preparations and visa clearance.</p>

      </div>
    </div>
  </div>
</div>



<div class="fh5co-section-with-image">

  <img src="assets/images/studying-students.jpg" alt="" style="width: 100%" class="img-responsive">
  <div class="fh5co-box">
    <h2>About Us</h2>
    <p>Cross Abroad is an educational consultancy based in Beirut, Lebanon. Our aim is to consult educated students with
      services related to university programs, application process, accommodation and visa across the MENA region.</p>
    <p><a routerLink="apply-now" class="btn btn-primary btn-outline with-arrow">Apply Now <i
          class="icon-arrow-right"></i></a></p>
  </div>

</div>

<div id="fh5co-testimonial" class="">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
        <h2>Happy Clients</h2>
        <p>"Your satisfaction is our # 1 priority" </p>
      </div>
      <div class="col-md-4 text-center item-block">
        <blockquote>
          <p>&ldquo; They provided outstanding assistance, supporting me at every stage of the procedure, especially my
            counselor. He assisted me in deciding on a university, finding an apartment, and finalizing my
            applications. &rdquo;</p>
          <p><span class="fh5co-author primary-text"><cite>Eve / Lebanon</cite></span>
            <!-- <i class="icon twitter-color icon-twitter pull-right"></i> -->
          </p>

        </blockquote>
      </div>
      <div class="col-md-4 text-center item-block">
        <blockquote>
          <p>&ldquo; Cross Abroad is a fantastic institution with a welcoming staff, particularly my counselor, who has
            helped and continues to support me through these trying times. I loved how you showed concern for other
            people and responded to their questions. Thank you so much, and I hope that I will be able to continue my
            education. &rdquo;</p>
          <p><span class="fh5co-author primary-text"><cite>Jana / Lebanon</cite></span>
            <!-- <i class="icon googleplus-color icon-google-plus pull-right"></i> -->
          </p>
        </blockquote>
      </div>
      <div class="col-md-4 text-center item-block">

        <blockquote>
          <p>&ldquo; Thank you for the best possible experience. I was hesitant at first with my experience with Cross
            abroad agency, but they showed me trust from the very beginning and guided me with the best possible journey
            abroad.

            &rdquo;</p>
          <p><span class="fh5co-author primary-text"><cite>Michael / Lebanon</cite></span>
            <!-- <i class="icon facebook-color icon-facebook pull-right"></i> -->
          </p>
        </blockquote>
      </div>
    </div>
  </div>
</div>

<div id="fh5co-blog" class="">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
        <h2>Countries</h2>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-3" style="padding-top: 20px; animation: pulse 2s infinite;">
        <img src="assets/images/france.png" style="max-width:100%;
            max-height:100%;">
      </div>

      <div class="col-md-3" style="padding-top: 20px;animation: pulse 2s infinite;">
        <img src="assets/images/united-kingdom.png" style="max-width:100%;
        max-height:100%;">
      </div>
      <div class="col-md-3" style="padding-top: 20px;
      animation: pulse 2s infinite;
      ">
        <img src="assets/images/canada.png" style="max-width:100%;
        max-height:100%; ">
      </div>
      <div class="col-md-3" style="padding-top: 20px;
      animation: pulse 2s infinite  ;
      ">
        <img src="assets/images/italy.png" style="max-width:100%;
        max-height:100%;
        ">
      </div>
    </div>
  </div>
</div>


<app-apply-now-card></app-apply-now-card>
