    <div class="fh5co-cta" style="background-image: url(assets/images/airplane.jpg);">
      <div class="overlay"></div>
      <div class="container">
        <div class="col-md-12 text-center ">
          <!-- <h3>Our expert counsellors are always on hand to help you with every aspect of your university application. And we'll never charge you</h3> -->
          <h3>“One’s destination is never a place but a new way of seeing things.” - Henry Miller</h3>
          <p><a routerLink="apply-now"class="btn btn-primary btn-outline with-arrow">Apply Now<i class="icon-arrow-right"></i></a></p>
        </div>
      </div>
    </div>
