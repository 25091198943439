<header id="fh5co-header" role="banner" >
  <div class="container" >
    <div class="header-inner row" >
      <h1 class="col-md-2" >
        <a href="#">
          <img src="assets/images/logo.png" class="header-logo">
          <!-- Cross Abroad -->
          <!-- <span>.</span> -->
        </a>
      </h1>
      <nav role="navigation col-md-10">
        <ul>
          <li><a href="index.html">Home</a></li>
          <li><a routerLink="apply-now">Register</a></li>
          <li><a routerLink="about-us">About Us</a></li>
          <li><a routerLink="contact-us">Contact Us</a></li>
          <li class="cta">
            <!-- <a routerLink="apply-now" style="color: #33c9ff" >Apply Now</a> -->
            <a routerLink="apply-now" class="btn btn-primary btn-outline">Apply Now</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
