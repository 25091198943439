import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apply-now-card',
  templateUrl: './apply-now-card.component.html',
  styleUrls: ['./apply-now-card.component.css']
})
export class ApplyNowCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
