<footer id="fh5co-footer" role="contentinfo">

  <div class="container">
    <div class="col-md-3 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
      <h3>About Us</h3>
      <p>Cross Abroad is an educational consultancy based in Beirut, Lebanon. </p>
      <p><a routerLink="apply-now" class="btn btn-primary btn-outline with-arrow btn-sm">Apply Now <i
            class="icon-arrow-right"></i></a></p>
    </div>
    <div class="col-md-6 col-md-push-1 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
      <h3>Menu</h3>
      <ul class="float">
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="/apply-now">Register</a></li>
        <li><a routerLink="/about-us">About Us</a></li>
        <li><a routerLink="/contact-us">Contact Us</a></li>
      </ul>
      <ul class="float">
        <!-- <li><a routerLink="/faq">FAQ</a></li> -->
        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
      </ul>
    </div>

    <div class="col-md-2 col-md-push-1 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
      <h3>Follow Us</h3>
      <ul class="fh5co-social">
        <li><a href="https://www.facebook.com/Beef3aRgif"><i class="icon-facebook"></i></a></li>
        <li style="margin-left: 30px;"><a href="https://instagram.com/crossabroadlb"><i class="icon-instagram"></i></a></li>
      </ul>
    </div>


    <div class="col-md-12 fh5co-copyright text-center">
      <p>&copy; 2022. All Rights Reserved. <span>Designed by <a href="http://revojok.com" target="_blank">Revojok</a>
        </span></p>
    </div>

  </div>
</footer>
