<div style="background-image: url(assets/images/data-security-t.jpg); background-size: 100%; text-align: center;
  vertical-align: middle;
  line-height: 300px;
  background-position: center;
    "
  class="hero-background"
  >
  <!-- <h1 class="strong" style="padding-top: 300px; color: white">Apply Now</h1> -->
  <h1 class="title hero-text">
    <span class="title-part line-1" style="padding-top: 35px;">Privacy Policy</span>
  </h1>
</div>

<div class="container" style="padding: 100px 0 100px 0;">
  <h1>Privacy and Policy:</h1>

  <h2>Privacy in Lebanon: Cross Abroad</h2>
  <p>
    Cross Abroad gathers and handles personal data about you as part of the application process. Our
    company I s dedicated to being direct and clear on just how it acquires and uses personal data, as well as
    how it complies with its data protection obligations
  </p>
  <h2>
    Control method of data
  </h2>
  <p>
    Cross Abroad, is the Data Controller.
    Please contact us if you have any questions regarding how Cross Abroad utilizes your personal data.
    Info@crossabroad.com.
  <h2>
    What kind of data does Cross Abroad obtain?
  </h2>
  <p>
    The following is a list of the types of information that may be collected from you:
  <ul>
    <li>
      Your name, along with your address, email address, and phone number
      Your social security number, as well as your date of birth, country of residence, and nationality,
      We also take note of your passport and visa details.
    </li>
    <li>
      Details about your school and work experience, as well as your GPA.
      You have attended school(s), sixth form college(s), and other institutions or universities.
      The schools you've attended and the locations you've worked, as well as the courses you've taken,
    </li>
    <li>
      Information that will be used to contact you or your family members in the event of an emergency.
    </li>
    <li>
      Information on your family or personal circumstances, as well as both academic and academicrelated information
      and extracurricular activities, as long as they are related to the course.
    </li>
    <li>
      Financial data collected for the administration of fees and charges, payments, loans, scholarships,
      studentships, and other forms of financial assistance
    </li>
  </ul>
  <strong>
  Personal ‘special categories' must also be collected and processed by Cross Abroad.
  </strong>
  <h2>
  'Personal data' as defined by the Cross Abroad includes:
  </h2>
  • Your healthcare and disabilities, in order to provide assistance and enable you to attend university. As
  well as to assess course applicability
  <br>
  • Preference for male or female
  <br>
  • Prior criminal convictions, which may have an impact on a university admission and immigration
  application.
  <br>
  You can request access to the information we hold on you by sending an email to
  Info@crossabroad.com
  <br>
  If you do need to amend your personal information before enrolling, you may do so at any time.
  <br>
  Call us to speak with the dedicated counselor who has been assigned to you.
  <br>
  <h2>
  Why do we gather your data?
  </h2>

  • We gather and process your information to process and apply on your behalf to partner universities of
  Cross Abroad, we assess your suitability to attend one of our partners courses, and communicate with
  you about decisions on your application, and about preparing for and making it to university.
  <br>
  • To inform you and give you access to informative run events.
  <br>
  • Where consent has been granted, such as registration, electronic correspondence, event attendance,
  data collected may be shared with
  <h2>
  Partner Universities in accordance to our Privacy Policies that we have in place with these institutions.
  </h2>
  <br>
  • We also have a duty to report to statutory authorities on data such as the qualifications our applicants
  enter university with.
  <h2>
      We may gather your personal information in a variety of methods, including:
      </h2>
  <br>
  • If you select Cross Abroad to submit your application on your behalf, a qualified Cross Abroad student
  will do so.
  <br>
  Our counselor will ask for the documentation that our partner university requires
  <br>
  <h2>
  Guidelines for applications:
  </h2>
  • Information from other parties, such as your past or current school, sixth form college, institution, or
  companies who may be able to supply you with a reference or who may be able to provide you with a
  recommendation may be interested in sponsoring your research.
  <h2>
  What kind of storage do you have for your data?
  </h2>
  Your personal information is stored in our business database and may only be viewed by you.
  <br>
  Counselors assigned, members of the Operations Team assigned, and Directors of Cross Abroad
  <h2>
  Who has access to the information?
  </h2>
  Cross Abroad may share your information with a number of internal departments as part of the normal
  processing of your application.
  <br>
  • If you notify us about a condition, we will share that information with the Regional Office.
  <br>
  • If you've applied for a position at one of our university partners, they'll send on your information.
  <br>
  <strong>
  Whether providing personal data is a statutory or contractual requirement and the consequences for
  failing to provide the data</strong>
  <br>
  We are processing your data as part of the contract we have with you.
  <br>
  <strong>
  The existence of automated decision making, including profiling, information about the logic involved,
  including the significance and the envisaged consequences of such processing for the data subject
  </strong>
  <br>
  We will not use your personal data for automated decision making or profiling about you as an
  individual.
  <br>
  The right to complain to the company
  If you are unsatisfied with the way that Global Study UK has processed your personal data, or have any
  questions or concerns about your data please contact.
  info@crossabroad.com

</div>
