<div style="background-image: url(assets/images/32512.jpg); background-size: 100%; text-align: center;
  vertical-align: middle;
  line-height: 600px;
  background-position: center;
  height: 600px;
  animation: shrink 5s infinite alternate;
  ">
  <!-- <h1 class="strong" style="padding-top: 300px; color: white">Our Partners</h1> -->
  <h1 class="title" style="padding-top: 300px;">
    <span class="title-part line-1">Our Partners</span>
  </h1>
</div>

<div class="fh5co-team ">
  <div class="container">

    <div class="row">

      <div class="col-md-4 fh5co-staff">
        <img src="assets/images/person3.jpg" alt="Free HTML5 Bootstrap Template" class="img-responsive">
        <h3>University of Berlin</h3>
        <h4>Berlin, Germany</h4>
        <!-- <ul class="fh5co-social">
            <li><a href="#"><i class="icon-google"></i></a></li>
            <li><a href="#"><i class="icon-dribbble"></i></a></li>
            <li><a href="#"><i class="icon-twitter"></i></a></li>
            <li><a href="#"><i class="icon-facebook"></i></a></li>
            <li><a href="#"><i class="icon-instagram"></i></a></li>
          </ul> -->
      </div>
      <div class="col-md-4 fh5co-staff">
        <img src="assets/images/person2.jpg" alt="Free HTML5 Bootstrap Template" class="img-responsive">
        <h3>University of Bristol</h3>
        <h4>Bristol, England</h4>
      </div>
      <div class="col-md-4 fh5co-staff">
        <img src="assets/images/person4.jpg" alt="Free HTML5 Bootstrap Template" class="img-responsive">
        <h3>University of Berlin</h3>
        <h4>Berlin, Germany</h4>
      </div>
    </div>
    <div class="row my-5">

      <div class="col-md-4 fh5co-staff">
        <img src="assets/images/person3.jpg" alt="Free HTML5 Bootstrap Template" class="img-responsive">
        <h3>University of Berlin</h3>
        <h4>Berlin, Germany</h4>
      </div>
      <div class="col-md-4 fh5co-staff">
        <img src="assets/images/person2.jpg" alt="Free HTML5 Bootstrap Template" class="img-responsive">
        <h3>University of Berlin</h3>
        <h4>Berlin, Germany</h4>
      </div>
      <div class="col-md-4 fh5co-staff">
        <img src="assets/images/person4.jpg" alt="Free HTML5 Bootstrap Template" class="img-responsive">
        <h3>University of Berlin</h3>
        <h4>Berlin, Germany</h4>
      </div>
    </div>
  </div>
</div>
