<div style="background-image: url(assets/images/paris.jpg); background-size: 100%; text-align: center;
  vertical-align: middle;
  line-height: 600px;
  background-position: center;
  " class="hero-background">
  <!-- <h1 class="strong title" style="padding-top: 300px; color: white">Contact Us</h1> -->
  <h1 class="title hero-text">
    <span class="title-part line-1" style='padding-top: 35px;'>Contact Us</span>
  </h1>
</div>

<div class="fh5co-contact">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <h3>Contact Info.</h3>
        <ul class="contact-info">
          <li><i class="icon-map"></i>United Nations Street, Jnah, Beirut, Lebanon</li>
          <li><i class="icon-phone"></i>+961 76 06 30 32</li>
          <li><i class="icon-envelope"></i><a href="#">info@crossabroad.com</a></li>
          <li style="margin-top: 79px!important;">
            <button class="">
              <a class="" href="https://wa.me/96176063032"><i class="fa fa-whatsapp btn btn-success"
                  style="color: #fff;"> Message us on
                  whatsapp</i></a>
            </button>
          </li>
        </ul>
      </div>
      <div class="col-md-8 col-md-push-1 col-sm-12 col-sm-push-0 col-xs-12 col-xs-push-0">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input class="form-control" name="name" [(ngModel)]="message.name" placeholder="Name" type="text">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input class="form-control" placeholder="Email" name="email" [(ngModel)]="message.email" type="text">
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <textarea name="message" [(ngModel)]="message.message" class="form-control" id="" cols="30" rows="7"
                placeholder="Message"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-primary" type="button" (click)="send()" [disabled]="isSending">
                <span *ngIf="isSending"><img src="{{loadingGifUrl}}" width="20"></span>
                <span *ngIf="!isSending">Send Message</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <!-- <div class="col-md-1"></div> -->
  <div class="col-md-12">
    <div id="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d828.1696137428992!2d35.49247669675015!3d33.87217671090581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x565ce880907a6530!2zMzPCsDUyJzIwLjciTiAzNcKwMjknMzMuMSJF!5e0!3m2!1sen!2slb!4v1642528683214!5m2!1sen!2slb"
        width="100%" height="500px" frameborder="0" style="border:0" allowfullscreen></iframe>
    </div>
  </div>
</div>


<app-apply-now-card></app-apply-now-card>
