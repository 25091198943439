<div style="background-image: url(assets/images/london.jpg); background-size: 100%; text-align: center;
  vertical-align: middle;
  background-attachment: fixed;
  line-height: 300px;
  background-position: center;
  height: 300px;
  animation: shrink 10s infinite alternate;
  ">
  <!-- <h1 class="strong" style="padding-top: 300px; color: white">Apply Now</h1> -->
  <h1 class="title" style="padding-top: 150px;">
    <span class="title-part line-1" style="text-transform: none!important;">FAQs</span>
  </h1>
</div>

<div class="faq_area section_padding_130" id="faq">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-lg-6">
        <!-- Section Heading-->
        <div class="section_heading text-center wow fadeInUp" data-wow-delay="0.2s"
          style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
          <h2><span>Frequently </span> Asked Questions</h2>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <!-- FAQ Area-->
      <div class="col-12 col-sm-10 col-lg-10">
        <div class="accordion faq-accordian" id="faqAccordion">
          <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s"
            style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
            <div class="card-header" id="headingOne">
              <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne">How can I install this app?<span class="lni-chevron-up"></span></h6>
            </div>
            <div class="collapse" id="collapseOne" aria-labelledby="headingOne" data-parent="#faqAccordion">
              <div class="card-body">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi
                  sapiente vitae suscipit.</p>
                <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
              </div>
            </div>
          </div>
          <div class="card border-0 wow fadeInUp" data-wow-delay="0.3s"
            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
            <div class="card-header" id="headingTwo">
              <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                aria-controls="collapseTwo">The apps isn't installing?<span class="lni-chevron-up"></span></h6>
            </div>
            <div class="collapse" id="collapseTwo" aria-labelledby="headingTwo" data-parent="#faqAccordion">
              <div class="card-body">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi
                  sapiente vitae suscipit.</p>
                <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
              </div>
            </div>
          </div>
          <div class="card border-0 wow fadeInUp" data-wow-delay="0.4s"
            style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
            <div class="card-header" id="headingThree">
              <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
                aria-controls="collapseThree">Contact form isn't working?<span class="lni-chevron-up"></span></h6>
            </div>
            <div class="collapse" id="collapseThree" aria-labelledby="headingThree" data-parent="#faqAccordion">
              <div class="card-body">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi
                  sapiente vitae suscipit.</p>
                <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Support Button-->
        <div class="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp"
          data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
          <i class="lni-emoji-sad"></i>
          <p class="mb-0 px-2">Can't find your answers?</p>
          <a routerLink="/apply-now"> Contact us</a>
        </div>
      </div>
    </div>
  </div>
</div>
