<app-header></app-header>

<router-outlet></router-outlet>

<!-- {/* WhatsApp icon */} -->
      <a
        href="https://wa.me/96176063032"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>

<app-footer></app-footer>
