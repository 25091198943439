<div style="background-image: url(assets/images/graduation-hats.jpg); background-size: 100%; text-align: center;
  vertical-align: middle;
  line-height: 600px;
  background-position: center;
  " class="hero-background">
  <!-- <h1 class="strong" style="padding-top: 300px; color: white">Apply Now</h1> -->
  <h1 class="title hero-text">
    <h2 class='hero-quote'>
      Your story begins here! </h2>
    <span class="title-part line-1">Register Now</span>
  </h1>
</div>

<div class="container">
  <div style="color: black; text-align: center; margin-top: 60px">FILL UP THE FORM.</div>
  <h1
    style="color: black; text-align: center; margin-top: 30px; animation: slideToRight 5s; text-transform: uppercase;">
    Inhale your future,
    Exhale your past.</h1>
  <form>
    <input name="firstName" [(ngModel)]="application.firstName" type="text" class="feedback-input"
      placeholder="First Name" />
    <input name="lastName" [(ngModel)]="application.lastName" type="text" class="feedback-input"
      placeholder="Last Name" />
    <input name="dateOfBirth" [(ngModel)]="application.dateOfBirth" type="text" class="feedback-input"
      placeholder="Date Of Birth (DD/MM/YYYY)" />
    <input name="phoneNumber" [(ngModel)]="application.phoneNumber" type="text" class="feedback-input"
      placeholder="Phone Number (Including country code)" />
    <input name="email" [(ngModel)]="application.email" type="text" class="feedback-input" placeholder="Email" />
    <input name="country" [(ngModel)]="application.country" type="text" class="feedback-input" placeholder="Country" />
    <input name="city" [(ngModel)]="application.city" type="text" class="feedback-input" placeholder="City" />
    <input name="previousUniversity" [(ngModel)]="application.previousUniversity" type="text" class="feedback-input"
      placeholder="Current/Previous School or University" />
    <input name="course" [(ngModel)]="application.course" type="text" class="feedback-input"
      placeholder="Course You Would Like To Study" />
    <select name="referencedBy" class="feedback-input" [(ngModel)]="application.referencedBy"
      placeholder="How did you hear about us"
      class="feedback-input form-item__element--select"
      required
      >
      <option value=null selected disabled>How did you hear about us?</option>
      <option value="INSTAGRAM">Instagram</option>
      <option value="FACEBOOK">Facebook</option>
      <option value="TWITTER">Twitter</option>
      <option value="GOOGLE">Google</option>
      <option value="POSTER">Flyer/Poster</option>
      <option value="COUNSELLOR">School Counselor</option>
      <option value="PERSON">Family/Friend referral</option>
    </select>
    <span style="margin-top: 20px;">By clicking Submit, you consent to your data being handled as per Cross Abroad's
      Privacy Policy. Click here
      to view. </span>
      <button class="btn btn-primary" style="margin-top: 20px;"  type="button" (click)="store()" [disabled]="isSending">
        <span *ngIf="isSending"><img src="{{loadingGifUrl}}" width="20"></span>
        <span *ngIf="!isSending">Submit</span>
      </button>
    <!-- <input type="button" (click)="store()" value="SUBMIT" /> -->
  </form>
</div>
