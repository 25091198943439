<div style="padding-left: auto;
padding-right: auto;
width: 100%;
padding-top: 150px;
padding-bottom: 50px;
background-image: linear-gradient(to bottom, rgb(60, 60, 60) , rgb(0, 162, 255))!important; ;
">
  <div class="container d-flex justify-content-center welcome-box">

    <div style="width: 100%; display: flex; justify-content: center;">
      <img src="assets/images/logo.png" style="width: 70px; ">
    </div>
    <h2 style="display: flex; justify-content: center;">
      Application sent successfully!
    </h2>
    <div style="display: flex; justify-content: center;">
      <img class="container d-flex justify-content-center" style="width: 150px;" src="https://i.gifer.com/7efs.gif">
      <!-- src="https://i.gifer.com/XwI5.gif" -->

    </div>
    <h3 style="display: flex; justify-content: center;">
      Thank you for registering on our website, our team will contact
      you as soon as possible
    </h3>
    <div style="display: flex; justify-content: center; margin-top: 50px;">
      <a href="index.html"><button class="btn btn-primary">Home Page</button></a>
    </div>
  </div>
